<template>
  <Member
    title="Dr.ssa Lavinia Barbieri"
    description="Chirurgia Generale e Chirurgia Gastroenterologica"
    text="l campi di maggior interesse clinico e chirurgico della dott.ssa Barbieri sono patologie funzionali di esofago e stomaco, come la malattia da reflusso gastro-esofageo e le ernie jatali, l’esofago di Barrett, i disturbi di motilità esofagea e i diverticoli. Ha frequentato, in Italia e all’estero, centri specialistici ad alto volume ove si è formata ed ha acquisito sia tecniche di diagnostica con tecnologie di ultima generazione (manometria ad alta risoluzione, ph-impedezometria, monitoraggio ph-metrico con capsula BRAVO e misurazione planimetrica dell’impedenza con EndoFlip) e sia di trattamento di queste patologie, con approccio chirurgico o endoscopico. Collabora attivamente inoltre nel percorso di diagnosi e cura dei pazienti con malattia oncologia di esofago e stomaco come anche del tratto gastroenterico basso. 

Ha partecipato a studi multicentrici internazionali di ricerca per il trattamento chirurgico ed endoscopico delle malattie dell’esofago. "
    :formation="[
      'Laurea in Medicina e Chirurgia – Università degli Studi di Milano – 2012',
      'Research fellowship in upper GI surgery and operative endoscopy – Guy’s and St Thomas’ Hospital – NSH Foundation Trust – London – 2017',
      'Specializzazione in Chirurgia Generale – Università degli Studi di Milano – 2018',
    ]"
    :pub="true"
    publication="È autrice di diverse pubblicazioni scientifiche su riviste internazionali e di capitoli di libri specialistici. "
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";

export default {
  name: "Barbieri",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
